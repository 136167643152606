import {MuiForm} from "../../../shared/components/mui-form/mui-form";
import {url} from "../../../../shared/utils";

export const RegistrationPasswordModal = (function () {

    const exports = {};

    exports.init = () => {
        MuiForm.init();
        
        // will do keyup and paste events
        $('input[name=temporary_password]').on('input', (event) => {
            if ($(event.target).data('isValid')) {
                $('#enter_password_box').slideDown('fast');
                MuiForm.unlockSubmit();
            } else {
                $('#enter_password_box').slideUp();
                MuiForm.lockSubmit();
            }
        });

        $('#resendCodeButton').click(function() {
            var btn = $(this).button('loading');
            var emlLogin = $(this).data("use-for");


            framework.post(url('api/resend_verification_code'), {
                _token: $('input[name="_token"]:first').val(),
                login: emlLogin,
                sendAccess: 1
            }, function(r) {
                btn.button('reset');
                tradeloop.show_modal_message("Successfully resent email.");
            }, function(e, data) {
                btn.button('reset');
                tradeloop.handleError(e, data, true);
            })
        });

        $('#form_submit').click(() => {
            if (!MuiForm.checkPasswordFields()) {
                return;
            }

            MuiForm.hideError();
            MuiForm.lockSubmit();
            MuiForm.showLoading('Activating Account.');



            postRegisterPassword();
        });
    }

    function postRegisterPassword() {
        framework.post(url('api/create_password'), {
            _token:             $('input[name="_token"]:first').val(),
            temporary_password: $('input[name=temporary_password]').val(),
            login:              $('input[name=login]').val(),
            password:           $('input[name=password]').val(),
        }, function(r) {
            onPostRegisterPasswordSuccess(r);
        }, function(e, data) {
            onPostRegisterPasswordError(e, data);
        });
    }

    function onPostRegisterPasswordSuccess(r) {

        Analytics.trackInGA('1a. Registration / Setup / Verification',{
            'event_cat_id': 27,
            'event_cat_name':'1a. Registration / Setup / Verification',
            'tl_event_id':150,
            'tl_event_name':'4. Password Created'
        });

        Analytics.trackConversion('sk1KCMqY0bYYEIvakv8D');

        if (typeof window.redirect_uri !== 'undefined') {
            window.location = window.redirect_uri;
        } else {
            window.location = window.SUCCESS_REDIRECT_URL;
        }
    }

    function onPostRegisterPasswordError(e, data) {
        tradeloop.handleError(e,data,true);

        MuiForm.unlockSubmit();
        MuiForm.hideLoading();
    }

    return exports;

})();
