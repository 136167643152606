'use strict';

export const AjaxDataLoad = (function () {
    
    var exports = {};

    exports.getAjaxDataLoad = function () {
		var data = {
            "_token": $('input[name="_token"]:first').val(),
            "x-sender": "js",
        };

        if (typeof tlpxtoken != 'undefined') {
            data.tlpxtoken = tlpxtoken;
        }

        return data;
    };

    exports.extendDataLoad = function (obj) {
        return $.extend(this.getAjaxDataLoad(), obj);
    };
    
    exports.extendObject = function (obj, objExtend) {
        return $.extend(obj, objExtend);
    };

    return exports;

})();
