import {RegistrationModal} from "./pages/RegistrationModal";
import {RegistrationPasswordModal} from "./pages/RegistrationPasswordModal";

export const RegistrationFlow = (function () {

    const exports = {};

    exports.initRegistrationModal = () => {
        RegistrationModal.init(); 
    }

    exports.initRegistrationPasswordModal = () => {
        RegistrationPasswordModal.init();
    }

    return exports;

})();
