import "../modals/registration/scss/index.scss";

import {RegistrationFlow} from "../modals/registration";

$(document).on('shown.bs.modal', '#modal_register', event => {
    RegistrationFlow.initRegistrationModal();
});

$(document).on('shown.bs.modal', '#modal_register_password', event => {
    RegistrationFlow.initRegistrationPasswordModal();
});
